body {
  display: flex;
}
/* * {
  outline: 1px solid red;
} */

#__next {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
}

::-webkit-scrollbar {
  width: 4px;
  padding-right: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  width: 6px;
  border-radius: 10px;
}

@media print {
  .no-print {
    display: none !important;
  }
}

.custom-drawer-content {
  max-width: 15rem;
}

.only-desktop {
  display: none;
}
.only-mobile {
  display: block;
}

@media only screen and (min-width: 62em) {
  .only-desktop {
    display: block;
  }
  .only-mobile {
    display: none !important;
  }
}

a:focus-visible {
  outline: 2px solid #3b82f6;
  outline-offset: 2px;
}
@media only screen and (max-width: 808em) {
  @media only screen and (max-width: 768px) {
    .intercom-app {
      div {
        position: absolute !important;
        bottom: 72px !important;
        right: 12px !important;
        transition: height 2s ease-in-out;
      }
      span {
        position: absolute !important;
        top: 40px !important;
      }
    }
  }
}
.rounded-svg {
  border-radius: 50%; /* Makes the SVG rounded */
}

.center {
  justify-content: center;
  align-items: center;
}
